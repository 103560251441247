import Vue from "vue";
import { loadCards } from "./giftlib";
import GiftCardBlock from "@/views/gift/GiftCardBlock.vue";
export default Vue.extend({
    components: { GiftCardBlock },
    props: {
        query: {
            type: Object,
        },
        basePath: {
            type: String,
            default: "greeting"
        },
        title: {
            type: String,
            default: "Crypto Greeting"
        }
    },
    data() {
        return {
            cssVars: {
                "--button-radius": "20px",
                "--small-button-radius": "20px",
            },
            occasions: [],
            cards: null,
        };
    },
    computed: {
        filtered() {
            if (this.query.occasion) {
                return this.cards.filter((it) => it.occasions?.includes(this.query.occasion));
            }
            return this.cards;
        },
    },
    methods: {
        personalize(card) { },
    },
    created() {
        loadCards().then((cards) => {
            this.cards = cards;
            let occasions = {};
            for (let it of cards) {
                if (it.occasions) {
                    for (let occ of it.occasions) {
                        if (!occasions[occ]) {
                            occasions[occ] = 0;
                        }
                        occasions[occ] += 1;
                    }
                }
            }
            let occ = [];
            for (let [key, val] of Object.entries(occasions)) {
                occ.push({
                    occasion: key,
                    count: val,
                });
            }
            this.occasions = occ;
        });
    },
});
