import Vue from "vue";
import { loadCard, loadRelay } from "@/views/gift/giftlib";
import GiftCardBlock from "@/views/gift/GiftCardBlock.vue";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import { clone } from "rambdax";
import Info from "@/components/Info.vue";
import { getNetwork } from "@/assets/lib/networks";
import LoadingButton from "@/components/LoadingButton.vue";
import DumbappSubmit from "@/components/dumbapp/submit/DumbappSubmit.vue";
import DumbappMixin from "@/components/dumbapp/DumbappMixin";
import { chainEqual } from "@blockwell/chains";
export default Vue.extend({
    components: { DumbappSubmit, LoadingButton, Info, ContractLoader, GiftCardBlock },
    mixins: [DumbappMixin],
    props: {
        tokenId: String,
        relayAddress: String,
        relayNetwork: String,
        basePath: {
            type: String,
            default: "greeting",
        },
        pageTitle: {
            type: String,
            default: "Crypto Greeting",
        },
    },
    data() {
        return {
            cssVars: {
                "--button-radius": "40px",
                "--small-button-radius": "40px",
            },
            card: null,
            token: null,
            contribData: null,
            relay: null,
            relayData: null,
            loading: false,
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        greetingData() {
            return this.$store.state.drafts.drafts[this.basePath];
        },
        serviceFee() {
            if (this.relayData && this.greetingData) {
                return this.relayData.serviceFee.times(this.greetingData.amount).toFormat();
            }
            return null;
        },
        serviceFeePercentage() {
            if (this.relayData) {
                return this.relayData.serviceFee.times(100).toFormat();
            }
            return null;
        },
        total() {
            if (this.relayData && this.greetingData) {
                return this.relayData.serviceFee.plus(1).times(this.greetingData.amount);
            }
            return null;
        },
        gasFee() {
            return this.relayData?.gas?.toFormat();
        },
        dumbappDeps() {
            return {
                contractId: this.relay?.id,
                tokenId: this.token?.id,
            };
        },
    },
    watch: {
        tokenId: {
            immediate: true,
            handler(val) {
                loadCard(val).then((it) => (this.card = it));
            },
        },
        dumbappDeps: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val.contractId && val.tokenId) {
                    this.api.greetingDumbapp(val.contractId, val.tokenId).then((res) => {
                        this.dumbapp = res;
                    });
                }
            },
        },
    },
    methods: {
        submit() {
            console.log(clone(this.greetingData));
            this.loading = true;
        },
    },
    created() {
        this.$store.restored.then(() => {
            let draft = this.$store.state.drafts.drafts[this.basePath];
            this.contribData = null;
            this.api.contracts.getOne(draft.token).then(async (it) => {
                this.token = it;
                if (chainEqual(it, "mainnet")) {
                    this.contribData = await this.api.contribData(it.address);
                    if (!this.contribData?.value) {
                        this.greetingData.usd = "";
                    }
                }
                else {
                    this.contribData = {
                        value: 0.1,
                        testnet: true,
                    };
                }
            });
            this.api.contracts
                .getOne({
                address: this.relayAddress,
                chainId: getNetwork(this.relayNetwork).chainId,
            })
                .then(async (it) => {
                this.relay = it;
                this.relayData = await loadRelay(it);
            });
        });
    },
});
