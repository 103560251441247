import Vue from "vue";
export default Vue.extend({
    name: "GiftCardBlock",
    props: {
        card: {
            type: Object,
        },
        hideName: Boolean,
    },
    data() {
        return {};
    },
});
