import Vue from "vue";
import { loadCard } from "@/views/gift/giftlib";
import GiftCardBlock from "@/views/gift/GiftCardBlock.vue";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import BigNumber from "bignumber.js";
import { chainEqual } from "@blockwell/chains";
export default Vue.extend({
    components: { ContractLoader, GiftCardBlock },
    props: {
        tokenId: String,
        relayAddress: String,
        relayNetwork: String,
        basePath: {
            type: String,
            default: "greeting",
        },
        title: {
            type: String,
            default: "Crypto Greeting",
        },
    },
    data() {
        return {
            cssVars: {
                "--button-radius": "40px",
                "--small-button-radius": "40px",
            },
            card: null,
            formData: {},
            token: null,
            contribData: null,
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
    },
    watch: {
        tokenId: {
            immediate: true,
            handler(val) {
                loadCard(val).then((it) => (this.card = it));
            },
        },
        formData: {
            deep: true,
            handler(val) {
                this.$store.dispatch("drafts/saveDraft", {
                    key: this.basePath,
                    value: val,
                });
                if (val.token !== this.token?.id) {
                    this.contribData = null;
                    this.api.contracts.getOne(val.token).then(async (it) => {
                        this.token = it;
                        if (chainEqual(it, "mainnet")) {
                            this.contribData = await this.api.contribData(it.address);
                            if (!this.contribData?.value) {
                                this.formData.usd = "";
                            }
                        }
                        else {
                            this.contribData = {
                                value: 0.1,
                                testnet: true,
                            };
                        }
                    });
                }
            },
        },
        "contribData.value": {
            immediate: true,
            handler(val) {
                if (val) {
                    if (this.formData.usd) {
                        this.updateAmount(this.formData.usd, val);
                    }
                }
            },
        },
    },
    methods: {
        loadToken(data) {
            Vue.set(this.formData, "token", data?.id);
        },
        formatValue(val) {
            return new BigNumber(val).toFormat(2);
        },
        updateAmount(usd, value) {
            if (usd && value) {
                Vue.set(this.formData, "amount", new BigNumber(usd).div(value).sd(5).toString(10));
            }
        },
        usdInput(val) {
            this.updateAmount(val, this.contribData?.value);
            this.formData.valueInput = "usd";
        },
        amountInput(val) {
            if (val && this.contribData?.value) {
                Vue.set(this.formData, "usd", new BigNumber(val).times(this.contribData.value).sd(5).toString(10));
            }
            else {
                Vue.set(this.formData, "usd", "");
            }
            this.formData.valueInput = "amount";
        },
        submit() {
            this.$router.push(`/${this.basePath}/summary/${this.tokenId}`);
        },
    },
    created() {
        this.$store.restored.then(() => {
            let draft = this.$store.state.drafts.drafts[this.basePath];
            if (draft) {
                this.formData = draft;
            }
        });
    },
});
