import { loadBookContent } from "@/views/happs/book/lib/booklib";
import BigNumber from "bignumber.js";
import { Chain } from "@blockwell/chain-client";
let cached = null;
let cacheTimestamp = 0;
export async function loadCards() {
    if (!cached || cacheTimestamp < Date.now() - 60000) {
        let content = await loadBookContent(4, "0xd5c651ca53a25ac1a94d16f377249bbd141d190a", "cards", null);
        let cards = [];
        for (let it of content) {
            try {
                let parsed = JSON.parse(it.data);
                cards.push({
                    tokenId: it.name,
                    image: parsed.image,
                    artist: parsed.attributes?.find((it) => it.trait_type === "Artist")
                        ?.value,
                    name: parsed.name,
                    occasions: parsed.attributes
                        ?.filter((it) => !it.trait_type)
                        .map((it) => it.value),
                });
            }
            catch (err) {
                console.error(err);
            }
        }
        cached = cards;
        return cards;
    }
    return cached;
}
export async function loadCard(tokenId) {
    let cards = await loadCards();
    return cards.find((it) => it.tokenId === tokenId);
}
export async function loadRelay(contractData) {
    const read = Chain.curryRead(contractData.network, contractData.address, contractData.abi);
    let total = await read("percentageTotal", []).then((it) => new BigNumber(it).shiftedBy(-10));
    let gasPrice = await Chain.gasPrice(contractData.network);
    let gas;
    if ("maxFeePerGas" in gasPrice) {
        gas = new BigNumber(gasPrice.maxFeePerGas);
    }
    else {
        gas = new BigNumber(gasPrice.gasPrice);
    }
    gas = gas.times(220000).shiftedBy(-18).sd(3, BigNumber.ROUND_UP);
    return {
        percentageTotal: total,
        serviceFee: total.minus(1),
        gas,
    };
}
